import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { ReactComponent as UncheckedIcon } from '../../assets/Small-Unchecked-Checkbox.svg'
import { ReactComponent as CheckedIcon } from '../../assets/Small-Checked-Checkbox.svg'
import { ReactComponent as CSVIcon } from '../../assets/CSV.svg'
import { ReactComponent as EditIcon } from '../../assets/Edit.svg'
import { DexcomParticipant } from '../../types/dexcom-account'
import { formatDate } from '../../common/date'
import {
  Table,
  TableRow,
  TableHeaderRow,
  TableHeader,
  Header,
  TableData,
  ScrollContainer,
  TableBody,
  ColumnHeader,
} from '../common/table-components'

const Unchecked = styled(UncheckedIcon)`
  color: var(--main-color);
`
const Checked = styled(CheckedIcon)`
  color: var(--main-color);
`
const CSV = styled(CSVIcon)`
  color: var(--main-color);
`
const Edit = styled(EditIcon)`
  color: var(--main-color);
`

export const Button = styled.button`
  background-color: white;
  outline: none;
  border: none;
  &:disabled {
    opacity: 0.5;
  }
`

interface Props {
  data: DexcomParticipant[]
  sortedColumn: string
  selected: string[]
  export: (id: string) => void
  changeSort: (column: keyof DexcomParticipant) => void
  toggleSelectAll: (selected: boolean) => void
  toggleSelectRow: (id: string) => void
  edit: (id: string) => void
}

export const ParticipantList = (props: Props) => {
  const [height, setHeight] = useState(window.innerHeight)
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Gets the list of participant ids with an upload date
  const participantsWithData = () =>
    props.data.filter((p) => p.uploadDate).map((p) => p.id)

  // Returns true if all the participants with an upload date are selected
  const allSelected = () => {
    const idsWithUploads = participantsWithData()
    if (idsWithUploads.length < 2) return false
    return idsWithUploads.reduce(
      (p, n) => p && props.selected.includes(n),
      true
    )
  }

  const renderTableData = () => {
    return props.data.map((participant, i) => (
      <TableRow key={participant.id}>
        <TableData>
          <Button
            data-testid={`unchecked-${participant.id}`}
            disabled={!participant.uploadDate}
            onClick={() => props.toggleSelectRow(participant.id)}
          >
            {props.selected.includes(participant.id) ? (
              <Checked />
            ) : (
              <Unchecked />
            )}
          </Button>
        </TableData>
        <TableData>{participant.lastName}</TableData>
        <TableData>{participant.firstName}</TableData>
        <TableData>{participant.externalParticipantId}</TableData>
        <TableData>{participant.transmitterId}</TableData>
        <TableData>
          {participant.uploadDate ? formatDate(participant.uploadDate) : ''}
        </TableData>
        <TableData>
          <Button
            data-testid={`csvExport-${participant.id}`}
            disabled={!participant.uploadDate}
            onClick={() => props.export(participant.id)}
          >
            <CSV />
          </Button>
          <Button onClick={() => props.edit(participant.id)}>
            <Edit />
          </Button>
        </TableData>
      </TableRow>
    ))
  }

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <Header>
              <Button
                data-testid="unchecked-selectAll"
                disabled={participantsWithData().length < 2}
                onClick={() => props.toggleSelectAll(!allSelected())}
              >
                {allSelected() ? <Checked /> : <Unchecked />}
              </Button>
            </Header>
            <ColumnHeader
              click={props.changeSort}
              fieldName="lastName"
              sortedField={props.sortedColumn}
              title="Last Name"
            />
            <ColumnHeader
              data-testid="sort-first"
              click={props.changeSort}
              fieldName="firstName"
              sortedField={props.sortedColumn}
              title="First Name"
            />
            <ColumnHeader
              click={props.changeSort}
              fieldName="externalParticipantId"
              sortedField={props.sortedColumn}
              title="Participant ID"
            />
            <ColumnHeader
              click={props.changeSort}
              fieldName="transmitterId"
              sortedField={props.sortedColumn}
              title="Transmitter ID"
            />
            <ColumnHeader
              click={props.changeSort}
              fieldName="uploadDate"
              sortedField={props.sortedColumn}
              title="Upload Date"
            />
            <Header>Actions</Header>
          </TableHeaderRow>
        </TableHeader>
      </Table>
      <ScrollContainer style={{ maxHeight: `${height - 500}px` }}>
        <Table>
          <TableBody>{renderTableData()}</TableBody>
        </Table>
      </ScrollContainer>
    </>
  )
}

import { Data } from '../types/service'
import { Egv, DexcomParticipant } from '../types/dexcom-account'
import { get } from './http'

export async function getParticipantData(): Promise<Data<DexcomParticipant[]>> {
  try {
    const response = await get<DexcomParticipant[]>('participants')
    if (response.ok) {
      return {
        status: 'loaded',
        data: response.parsedBody,
      }
    } else {
      return {
        status: 'error', 
        error: new Error('Could not load participants')
      }
    }
  } catch (error) {
    return { status: 'error', data: [], error: error }
  }
}

export async function getEgvData(
  id: string,
  uploadDate: string
): Promise<Data<Egv[]>> {
  try {
    const response = await get<Egv[]>(`egvs?id=${id}&uploadDate=${uploadDate}`)
    if (response.ok) {
      return {
        status: 'loaded',
        data: response.parsedBody,
      }
    } else {
      return {
        status: 'error',
        error: new Error('Could not load egv data')
      }
    }
  } catch (error) {
    return { status: 'error', data: [], error: error }
  }
}

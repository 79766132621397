import React from 'react'
import { Link } from 'react-router-dom'
import { DialogContainer } from '../common/simple-styled-components'

import { ReactComponent as CancelImg } from '../../assets/close-24px.svg'
import checkmark from '../../assets/Checkmark-O.png'
import './provision-dialog.css'

export function ProvisionSuccess(props: any) {
  return (
    <DialogContainer>
      <Link to="/">
        <CancelImg className="messagebox-cancel"></CancelImg>
      </Link>
      <img
        className="dialog-image"
        src={checkmark}
        alt="Success Checkmark"
      ></img>
      <div className="messagebox-info">Successfully Added Participant</div>
    </DialogContainer>
  )
}

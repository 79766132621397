import React from 'react'
import Modal from 'react-modal'
import { DexcomParticipant } from '../../types/dexcom-account'
import { Container } from '../common/simple-styled-components'
import { ParticipantEdit } from '../participant-edit'

const customStyles = {
  content: {
    padding: '0',
    width: '900px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

interface Props {
  show: boolean
  participant: DexcomParticipant
  close: () => void
}
export const EditModal = (props: Props) => (
  <Modal
    appElement={document.getElementById('root')!}
    isOpen={props.show}
    style={customStyles}
  >
    <Container>
      <h1>Edit Participant</h1>
      <ParticipantEdit
        edit
        participant={props.participant}
        close={props.close}
      />
    </Container>
  </Modal>
)

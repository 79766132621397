import React from 'react'
import styled from 'styled-components'
import { BlockButton } from './simple-styled-components'
import { ReactComponent as SearchSvg } from '../../assets/Search.svg'

export const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  width: 80x;
  height: 80px;

  & .path {
    stroke: var(--main-color);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`
export const Spinner = () => (
  <StyledSpinner viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="2"
    />
  </StyledSpinner>
)

export const ParticipantSearch = styled.div`
  background: var(--white-color);
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
  height: 36px;
  width: 275px;
  position: absolute;
  top: 90px;
  right: 490px;
`
export const Search = styled.input`
  position: absolute;
  margin: 5px 0px 0px 10px;
  padding: 0px;
  background: var(--white-color);
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  height: 25px;
  width: 260px;
  outline: none;
`
export const SearchIcon = styled(SearchSvg)`
  color: var(--main-color);
  position: absolute;
  top: 7px;
  left: 240px;
  float: right;
`
export const ExportDataButton = styled(BlockButton as any)`
  position: absolute;
  right: 50px;
  top: 90px;
`
export const InfoText = styled.div`
  width: 100%;
  text-align: center;
`

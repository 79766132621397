import Keycloak from 'keycloak-js'
import * as ENV from '../config/env'

const _kc = Keycloak({
  realm: ENV.DEXCOM_KEYCLOAK_REALM,
  url: `${ENV.DEXCOM_KEYCLOAK_URL}/auth/`,
  clientId: ENV.DEXCOM_KEYCLOAK_CLIENT_ID,
})

let kcLogin = _kc.login
_kc.login = (
  options?: Keycloak.KeycloakLoginOptions
): Keycloak.KeycloakPromise<void, void> => {
  if (options) {
    options.idpHint = _rbe
    return kcLogin(options)
  } else {
    return kcLogin()
  }
}

let _userProfile: Keycloak.KeycloakProfile
let _rbe: string | undefined

/**
 * Sets the rbe (risk bearing entity) id.
 * @param value
 */
const setRbe = (value?: string | null) => {
  _rbe = value ? value : undefined
}

/**
 * The rbe (risk bearing entity) id taken from the sub-domain of the url hostname.
 */
const getRbe = () => _rbe

const doLogin = _kc.login

const doLogout = () => {
  let url = new URL(window.location.href)
  url.pathname = '/'
  _kc.logout({
    redirectUri: url.toString()
  })
}

/**
 * Checks that the idp claim from the session token matches the rbe id taken
 * from the url and if they don't match it forces the user to authenticate.
 */
function enforceIdentityProviderUrlMatch() {
  const parsedToken = _kc.tokenParsed as any
  if (parsedToken.idp && parsedToken.idp !== _rbe) {
    _kc.logout()
  }
}

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: 'login-required',
      pkceMethod: 'S256',
    })
    .then((authenticated: boolean) => {
      if (authenticated && _kc.tokenParsed) {
        enforceIdentityProviderUrlMatch()
        _kc
          .loadUserProfile()
          .then((profile: Keycloak.KeycloakProfile) => {
            _userProfile = profile
            onAuthenticatedCallback()
          })
          .catch((e) => {
            console.error('Unable to retrieve user profile', e)
          })
      } else {
        console.warn('not authenticated!')
        doLogin({ idpHint: _rbe })
      }
    })
}

const getToken = async (): Promise<string | void> => {
  await updateToken((refreshed) => {
    if (refreshed) {
      console.log('Token Refreshed')
    } else {
      console.log('Token still valid')
    }
    return true
  })
  // console.log(_kc.token)
  return _kc.token
}

interface UserTokens {
  idToken: string | void
  accessToken: string | void
}

const getTokens = async (): Promise<UserTokens> => {
  await getToken()
  return {
    idToken: _kc.idToken,
    accessToken: _kc.token,
  }
}

const isAuthenticated = () => _kc.authenticated

const updateToken = (
  successCallback:
    | ((value: boolean) => boolean | PromiseLike<boolean>)
    | null
    | undefined
) => {
  return _kc.updateToken(5).then(successCallback).catch(doLogin)
}

const getUsername = () =>
  _kc.authenticated ? `${_userProfile.firstName} ${_userProfile.lastName}` : ''

export default {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  getTokens,
  updateToken,
  getUsername,
  isAuthenticated,
  setRbe,
  getRbe,
}

import React from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import { Header, Content } from '../common/simple-styled-components'
import { NotFound } from '../error'
import { ProvisionFormBulk } from '../provision-bulk/provision-bulk-form'
import { ProvisionSingleForm } from '../provision-single'

export const Provision = () => (
  <Content>
    <h1>Add Participants</h1>
    <Header style={{ width: 'calc(100% - 22px)' }}>
      <NavLink
        className="nav-link"
        activeClassName="nav-link-active"
        to="/provision"
        exact
      >
        Add a Participant
      </NavLink>
      <NavLink
        className="nav-link"
        activeClassName="nav-link-active"
        to="/provision/bulk"
      >
        Add Many Participants
      </NavLink>
    </Header>
    <div style={{ margin: '5px' }}></div>
    <Switch>
      <Route exact path="/provision">
        <ProvisionSingleForm />
      </Route>
      <Route path="/provision/bulk">
        <ProvisionFormBulk />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
    
  </Content>
)

import React from 'react'
import { Link } from 'react-router-dom'
import { LinkButton, Content } from '../common/simple-styled-components'
import { ERROR_UPLOADER_UNAVAILABLE } from '../../config/strings'
import './error.css'

export default () => (
  <Content>
    <h1>Unavailable</h1>
    <p className='error'>{ERROR_UPLOADER_UNAVAILABLE}</p>
    <Link className="link-button-text" to="/">
      <LinkButton>
        back to participant list
      </LinkButton>
    </Link>
  </Content>
)

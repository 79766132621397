import { useState, useEffect } from 'react'
import { Data } from '../../types/service'
import { sort } from '../../common/sort-functions'

const applyFilterSort = <T>(
  data: T[],
  filter: string,
  sortKey: keyof T,
  ascending: boolean
) => {
  const filtered = data.filter((row: any) => {
    let keys = Object.keys(row)
    let include = false
    keys.forEach((k) => {
      include =
        include ||
        row[k].toString().toLowerCase().indexOf(filter.toLocaleLowerCase()) >= 0
    })

    return include
  })

  sort(filtered, sortKey, 'string', !ascending)

  return filtered
}

const useFilterList = <T>(
  initialData: T[],
  initialSortKey: keyof T,
  defaultAscending: boolean,
  initializer: () => Promise<Data<T[]>>
) => {
  const [filter, setFilter] = useState('')
  const [filteredData, setFilteredData] = useState<T[]>(initialData)
  const [sort, setSort] = useState({
    key: initialSortKey,
    ascending: defaultAscending,
    comparerType: 'string',
  })
  const [data, setData] = useState<Data<T[]>>({
    status: 'init',
    data: initialData,
  })

  useEffect(() => {
    let subscribed = true

    const handleRes = (res: Data<T[]>) => {
      if (subscribed) {
        setData(res)
      }
    }

    setData((data) => ({
      ...data,
      status: 'loading',
    }))

    initializer().then((res) => {
      handleRes(res)
    })

    return () => {
      subscribed = false
    }
  }, [initializer])

  useEffect(() => {
    if (data.status === 'loaded' && data.data) {
      setFilteredData(
        applyFilterSort(data.data, filter, sort.key as keyof T, sort.ascending)
      )
    }
  }, [filter, data, sort])

  /// Public method to toggle the sort 'ascending' state
  const toggleSort = (
    key: keyof T,
    comparerType?: 'string' | 'number' | 'Date'
  ) => {
    if (sort.key !== key) {
      setSort({
        ...sort,
        key: key,
        ascending: defaultAscending,
        comparerType: comparerType || 'string',
      })
    } else {
      setSort({
        ...sort,
        ascending: !sort.ascending,
        comparerType: comparerType || 'string',
      })
    }
  }

  return {
    status: data.status,
    filteredData,
    filter,
    setFilter,
    toggleSort,
    sort,
  }
}

export default useFilterList

import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import { Spinner } from '../common/styled'

const customStyles = {
  content: {
    padding: '0',
    width: '550px',
    height: '250px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const SpinnerContainer = styled.div`
  margin-left: 230px;
  margin-top: 60px;
`
const Label = styled.h4`
  text-align: center;
  margin: 5px 0 0 0;
  font-size: 20px;
  font-weight: normal;
`
interface Props {
  show: boolean
}

export const SpinnerModal = (props: Props) => (
  <Modal
    appElement={document.getElementById('root')!}
    isOpen={props.show}
    style={customStyles}
  >
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
    <Label>Loading...</Label>
  </Modal>
)

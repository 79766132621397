import styled from 'styled-components'
import { ReactComponent as DexcomLogo } from '../../assets/DiscoveryData.svg'
import { ReactComponent as DexcomLogoBlank } from '../../assets/DiscoveryData_nocolor.svg'

export const Content = styled.div`
  margin-left: 50px;
  margin-right: 50px;
`
export const Container = styled.div`
  background: var(--white-color);
  border-radius: 0px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
  padding: 20px;
`
export const BlockButton = styled.button`
  border: 0px;
  padding: 0px;
  height: 36px;
  width: 200px;
  color: var(--white-color);
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  background: var(--main-color);

  &:enabled {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`
export const LinkButton = styled.div`
  padding-top: 10px;
  height: 27px;
  width: 200px;
  background: var(--main-color);
  color: var(--white-color);
  text-transform: uppercase;
`
export const InputContainer = styled.div`
  position: relative;
  float: left;
  background: rgb(245, 245, 245);
  border-radius: 3px 3px 0px 0px;
  height: 56px;
  width: 260px;
  border-bottom-style: solid;
  border-width: 2px;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 41px;
  padding-left: 16px;
`
export const ErrorContainer = styled.div`
  color: var(--error-color);
  position: absolute;
  width: 200px;
  float: right;
  height: 100px;
  top: 30px;
  left: 675px;
`
export const DialogContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  background: var(--white-color);
  border-radius: 0px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.28);
  height: 248px;
  width: 551px;
  display: inline-block;
  margin: 0 auto;
  padding: 3px;
`
export const Header = styled.div`
  background: var(--white-color);
  border-radius: 0px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
  height: 60px;
  padding: 2px;
  padding-left: 20px;
`
export const HeaderLogo = styled(DexcomLogo)`
  color: #63a844;
  position: relative;
  height: 60px;
  width: 250px;
  float: left;
  margin-right: 20px;
`
export const Footer = styled.div`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  background-color: rgb(74, 74, 74);
  color: lightgrey;
  padding: 30px;
  height: 200px;
  width: 100%;
`
export const FooterLogo = styled(DexcomLogoBlank)`
  position: absolute;
  width: 200px;
  height: 50px;
  color: var(--white-color);
`
export const FlexListContainer = styled.div`
  padding: none;
  margin: none;
  display: flex;
  justify-content: flex-end;
`
export const FlexList = styled.div`
  width: 200px;
  margin-left: 50px;
`
export const ListHeader = styled.div`
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--white-color);
`
export const ListItem = styled.div`
  font-size: 14px;
  padding-top: 10px;
`
export const DocLink = styled.a`
  text-decoration: none;
  color: lightgrey;

  &:hover {
    text-decoration: underline;
  }
`
export const TableContainer = styled.div`
  background: var(--white-color);
  border-radius: 0px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
  max-height: calc(100% - 225px);
  padding-bottom: 60px;
  padding-top: 20px;
`

import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  useHistory,
  Switch,
} from 'react-router-dom'

import { TransmitterUpload } from './upload/transmitter-upload'
import { ProvisionSuccess } from './provision'
import { Provision } from './provision'
import { UserMenu } from './menu'
import { NotFound, Unavailable } from './error'
import UserService from '../services/user'
import Participants from './participants/participants'
import {
  Header,
  Footer,
  FlexListContainer,
  FlexList,
  FooterLogo,
  ListHeader,
  ListItem,
  DocLink,
  HeaderLogo,
} from './common/simple-styled-components'
import * as ENV from '../config/env'
import './App.css'

// Grab the document links into
const DEXCOM_HOME         = 'https://www.dexcom.com'
const CONTACT_LINK        = ENV.LINK_DEXCOM_CONTACT    || DEXCOM_HOME
const PRIVACY_LINK        = ENV.LINK_PRIVACY_POLICY    || DEXCOM_HOME
const TERMS_LINK          = ENV.LINK_TERMS_OF_USE      || DEXCOM_HOME
const SOFTWARE_GUIDE_LINK = ENV.LINK_SOFTWARE_GUIDE    || DEXCOM_HOME
const G6_HANDOUT_LINK     = ENV.LINK_G6_HANDOUT        || DEXCOM_HOME

const App: React.FC = () => {
  const [authenticated, setAuthenticated] = useState(false)
  const history = useHistory()
  const hostname = window.location.hostname
  const matches = hostname.match(/^([\w-]+)\./)
  const rbe = matches ? matches[1] : ENV.DEFAULT_RBE
  UserService.setRbe(rbe?.toString())

  useEffect(() => {
    UserService.initKeycloak(() => {
      console.info(`Keycloak initialized`)
      setAuthenticated(true)
    })
  }, [])

  useEffect(() => {
    return history.listen(() => {
      console.log('refreshing token')
      UserService.getToken()
    })
  }, [history])

  if (authenticated) {
    return (
      <>
        <Header>
          <HeaderLogo />
          <NavLink
            className="nav-link"
            activeClassName="nav-link-active"
            to="/"
            exact
          >
            Participants
          </NavLink>
          <NavLink
            className="nav-link"
            activeClassName="nav-link-active"
            to="/upload"
          >
            Transmitter Upload
          </NavLink>
          <UserMenu />
        </Header>
        <Switch>
          <Route exact path="/">
            <Participants />
          </Route>
          <Route path="/provision">
            <Provision />
          </Route>
          <Route path="/provision-success">
            <ProvisionSuccess />
          </Route>
          <Route path="/upload">
            <TransmitterUpload />
          </Route>
          <Route path="/unavailable">
            <Unavailable />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
        <Footer>
          <FooterLogo />
          <FlexListContainer>
            <FlexList>
              <ListHeader>ABOUT DEXCOM</ListHeader>
              <ListItem>
                <DocLink 
                  href={CONTACT_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Information
                </DocLink>
              </ListItem>
            </FlexList>
            <FlexList>
              <ListHeader>TERMS & POLICIES</ListHeader>
              <ListItem>
                <DocLink 
                  href={TERMS_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </DocLink>
              </ListItem>
              <ListItem>
                <DocLink 
                  href={PRIVACY_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </DocLink>
              </ListItem>
            </FlexList>
            <FlexList>
              <ListHeader>INFORMATION</ListHeader>
              <ListItem>
                <DocLink
                  href={SOFTWARE_GUIDE_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Software Guide
                </DocLink>
              </ListItem>
              <ListItem>
                <DocLink
                  href={G6_HANDOUT_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  G6 Pro Patient Handout
                </DocLink>
              </ListItem>
              <ListItem>
                <DocLink
                  href={require('../assets/static/bulk-template.csv')}
                  download="Participant Upload Template.csv"
                >
                  CSV Upload Template
                </DocLink>
              </ListItem>
            </FlexList>
          </FlexListContainer>
        </Footer>
      </>
    )
  } else {
    return <div>Authenticating</div>
  }
}

// Wrap the app component in the Router context container
// Correctly gives <App /> the right context
export default () => (
  <Router>
    <App />
  </Router>
)

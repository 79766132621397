
export const formatDate = (dateStr: string): string => {
  let outDate = ''
  const date = new Date(dateStr)
  const currentDate = new Date()
  if (
    date.getDate() === currentDate.getDate() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear()
  ) {
    outDate = 'Today, '
  } else {
    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear()
    outDate = `${month < 10 ? '0' + month : month}-${
      day < 10 ? '0' + day : day
    }-${year}, `
  }

  const hour = date.getHours()
  const minute = date.getMinutes()
  outDate += `${hour % 12}:${minute < 10 ? '0' + minute : minute} ${
    hour < 12 ? 'AM' : 'PM'
  }`

  return outDate
}

import { ExternalParticipant } from '../../types/dexcom-account'
import { NAME_REGEX, PID_REGEX, TXID_REGEX } from '../../common/validate'
import { 
  ERROR_INVALID_DATA_CHAR_LENGTH, 
  ERROR_INVALID_DATA_FIELD, 
  ERROR_INVALID_DATA_TXID, 
  ERROR_INVALID_DATA_TXID_LENGTH,
  ERROR_DATA_RULES,
} from '../../config/strings'

export interface FieldErrors {
  firstName: string
  lastName: string
  transmitterId: string
  externalParticipantId: string
  general: string
  system: string
}

export const getFieldErrors = (data: ExternalParticipant): FieldErrors => {
  const errors = {
    firstName: '',
    lastName: '',
    transmitterId: '',
    externalParticipantId: '',
    general: '',
    system: ''
  }

  // Check FirstName
  if (data.firstName.length > 64) {
    errors.firstName = ERROR_INVALID_DATA_CHAR_LENGTH
  } else if (data.firstName.length < 2 || !new RegExp(NAME_REGEX).test(data.firstName)) {
    errors.firstName = ERROR_INVALID_DATA_FIELD
    errors.general = ERROR_DATA_RULES
  }

  // Check LastName
  if (data.lastName.length > 64) {
    errors.lastName = ERROR_INVALID_DATA_CHAR_LENGTH
  } else if (data.lastName.length < 2  || !new RegExp(NAME_REGEX).test(data.lastName)) {
    errors.lastName = ERROR_INVALID_DATA_FIELD
    errors.general = ERROR_DATA_RULES
  }

  // Check externalParticipantId
  if (data.externalParticipantId.length > 64) {
    errors.externalParticipantId = ERROR_INVALID_DATA_CHAR_LENGTH
  } else if (data.externalParticipantId.length < 2 || !new RegExp(PID_REGEX).test(data.externalParticipantId)) {
    errors.externalParticipantId = ERROR_INVALID_DATA_FIELD
    errors.general = ERROR_DATA_RULES
  }

  // Check Transmitter Id
  if (data.transmitterId.length > 20) {
    errors.transmitterId = ERROR_INVALID_DATA_TXID_LENGTH
  } else if (
    !data.transmitterId.startsWith('3') &&
    !data.transmitterId.startsWith('9')
  ) {
    errors.transmitterId = ERROR_INVALID_DATA_TXID
  } else if (data.transmitterId.length < 2 || !new RegExp(TXID_REGEX).test(data.transmitterId)) {
    errors.transmitterId = ERROR_INVALID_DATA_FIELD
    errors.general = ERROR_DATA_RULES
  }

  return errors
}

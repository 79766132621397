import * as ENV from './env'

const parsePerPage = () => {
  let participantsPerPage = parseInt(
    ENV.PARTICIPANTS_PER_PAGE || ''
  )
  if (isNaN(participantsPerPage)) {
    participantsPerPage = 100
  }
  return participantsPerPage
}

export const PARTICIPANTS_PER_PAGE = parsePerPage()

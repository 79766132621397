import { useState, useEffect } from 'react'
import JSZip from 'jszip'
import { DexcomParticipant } from '../../types/dexcom-account'
import { getEgvData } from '../../common/fetch-requests'
import { Egv } from '../../types/dexcom-account'

const timeStampString = () => {
  const now = new Date()
  const month =
    now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1
  const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate()
  const year = now.getFullYear()

  return `${month}${day}${year}`
}

export const createCsvContent = (
  participantId: string,
  data: Egv[]
): string[] => {
  const csvHead = 'participant id, transmitter id, value, system time\n'
  const csvRows = data.map(
    (egv) =>
      `"=""${participantId}"""` + // Makes sure a number renders as a string
      ',' +
      egv.transmitterId +
      ',' +
      egv.value +
      ',' +
      egv.systemTime +
      '\n'
  )
  csvRows.splice(0, 0, csvHead)
  return csvRows
}

export enum ExportState {
  INIT,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

interface Status {
  state: ExportState
  data: Blob | undefined
  fileName: string
}

export const useExport = () => {
  const [status, setStatus] = useState<Status>({
    state: ExportState.INIT,
    data: undefined,
    fileName: '',
  })
  const [participants, setParticipants] = useState<DexcomParticipant[]>([])

  const reset = () => {
    setStatus({
      state: ExportState.INIT,
      data: undefined,
      fileName: '',
    })
  }

  useEffect(() => {
    let subscribed = true

    const handleError = (e: Error) => {
      if (subscribed) {
        setStatus((status) => {
          if (status.state === ExportState.IN_PROGRESS) {
            return {
              ...status,
              state: ExportState.ERROR,
            }
          }
          return {
            ...status,
          }
        })
      }
    }

    const handleSuccess = (data: Blob, filename: string) => {
      if (subscribed) {
        setStatus((status) => {
          if (status.state === ExportState.IN_PROGRESS) {
            return {
              ...status,
              state: ExportState.SUCCESS,
              data: data,
              fileName: filename,
            }
          }
          return {
            ...status,
          }
        })
      }
    }

    if (participants.length > 0) {
      setStatus((status) => ({
        ...status,
        state: ExportState.IN_PROGRESS,
        data: undefined,
        fileName: '',
      }))

      const promises = participants.map((p) =>
        getEgvData(p.id, p.uploadDate!).then((r) => {
          if (r.error) {
            throw r.error
          } else if (!r.data) {
            throw new Error('No Data')
          } else {
            return {
              externalId: p.externalParticipantId,
              transmitterId: p.transmitterId,
              r,
            }
          }
        })
      )

      Promise.all(promises)
        .then((ps) => {
          if (participants.length > 1) {
            // GENERATE A ZIP
            let zip = new JSZip()
            ps.forEach((p) => {
              let csv = createCsvContent(p.externalId, p.r.data!)
              const blob = new Blob(csv, { type: 'csv' })
              zip.file(`${p.transmitterId}.csv`, blob)
            })
            zip.generateAsync({ type: 'blob' }).then((b) => {
              handleSuccess(b, `${timeStampString()}.zip`)
            })
          } else {
            // GENERATE A SINGLE CSV
            const csv = createCsvContent(ps[0].externalId, ps[0].r.data!)
            const blob = new Blob(csv, { type: 'csv' })
            handleSuccess(blob, `${ps[0].transmitterId}.csv`)
          }
        })
        .catch((e) => {
          handleError(e)
        })
    }

    return () => {
      subscribed = false
    }
  }, [participants])

  return { exportStatus: status, setToExport: setParticipants, reset }
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Redirect } from 'react-router-dom'

import { ExternalParticipant } from '../../types/dexcom-account'
import { FieldErrors, getFieldErrors } from './validation'
import { useProvisionParticipantService } from '../../services/provision-participant/provision-participant-hook'
import { InputContainer, ErrorContainer } from '../common/simple-styled-components'
import { ERROR_DUPLICATE_TXID, ERROR_PROVISION_PARTICIPANT, MESSAGE_DUPLICATE_TXID } from '../../config/strings'

const Form = styled.form`
  position: relative;
`
const Input = styled.input`
`
const CancelButton = styled.button`
  background-color: white;
  position: absolute;
  right: 200px;
  bottom: 20px;
  border: none;
  user-select: none;
  outline: none;
`
const SaveButton = styled.input`
  position: absolute;
  border-radius: 2px;
  height: 36px;
  width: 140px;
  right: 10px;
  bottom: 10px;
  color: var(--white-color);
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: center;
  background: var(--main-color);

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`
const MAX_FIELD_LENGTH = 64

const emptyErrors: FieldErrors = {
  firstName: '',
  lastName: '',
  externalParticipantId: '',
  transmitterId: '',
  general: '',
  system: ''
}

interface Props {
  edit: boolean
  participant: ExternalParticipant
  close: () => void
}

export const ParticipantEdit = ({ participant, edit, close }: Props) => {
  const [dexcomParticipant, setDexcomParticipant] = useState(participant)
  const [errors, setErrors] = useState<FieldErrors>(emptyErrors)
  const { service, provisionParticipant } = useProvisionParticipantService(edit)

  useEffect(() => {
    if (service.status === 'error') {
      if (service.error.message === 'DUPLICATE') {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            system: ERROR_DUPLICATE_TXID.replace('{ID}', dexcomParticipant.transmitterId),
            general: MESSAGE_DUPLICATE_TXID
          }
        })
      } else {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            general: '',
            system: ERROR_PROVISION_PARTICIPANT
          }
        })
      }
    } else {
      setErrors((prevErrors) => {
        return { 
          ...prevErrors,
          system: '',
          general: ''
        }
      })
    }
  }, [service, setErrors, dexcomParticipant.transmitterId])

  // Handle change event for a form field
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    setDexcomParticipant((prevParticipant) => ({
      ...prevParticipant,
      [event.target.name]:
        event.target.name === 'transmitterId'
          ? event.target.value.toUpperCase()
          : event.target.value,
    }))
  }

  // Validate the fields and submit the data
  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()

    // Validate the dexcom participant fields
    const fieldErrors = getFieldErrors(dexcomParticipant)
    if (
      fieldErrors.firstName.length > 0 ||
      fieldErrors.lastName.length > 0 ||
      fieldErrors.externalParticipantId.length > 0 ||
      fieldErrors.transmitterId.length > 0
    ) {
      setErrors(fieldErrors)
      return
    } else {
      setErrors(emptyErrors)
    }

    // No Errors, Submit the data
    provisionParticipant(dexcomParticipant)
  }

  // Can only submit when all required fields contain data.
  // Further validation shall occur on submit
  const canSubmit = () => {
    return (
      dexcomParticipant.firstName.length > 0 &&
      dexcomParticipant.lastName.length > 0 &&
      dexcomParticipant.externalParticipantId.length > 0 &&
      dexcomParticipant.transmitterId.length > 0
    )
  }

  if (service.status === 'loaded') {
    return <Redirect to="/provision-success" />
  }

  return (
    <Form onSubmit={handleSubmit} style={{ height: 'calc(60vh - 200px)' }}>
      <InputContainer>
        <label>
          First Name:
          <Input
            type="text"
            name="firstName"
            maxLength={MAX_FIELD_LENGTH}
            value={dexcomParticipant.firstName}
            onChange={handleChange}
          />
        </label>
        <div className="field-error">{errors.firstName}</div>
      </InputContainer>
      <InputContainer style={{ float: 'left' }}>
        <label>
          Last Name:
          <Input
            type="text"
            name="lastName"
            maxLength={MAX_FIELD_LENGTH}
            value={dexcomParticipant.lastName}
            onChange={handleChange}
          />
        </label>
        <div className="field-error">{errors.lastName}</div>
      </InputContainer>
      <div style={{ clear: 'both' }} />
      <InputContainer>
        <label>
          Transmitter ID:
          <Input
            disabled={edit}
            type="text"
            name="transmitterId"
            maxLength={MAX_FIELD_LENGTH}
            value={dexcomParticipant.transmitterId}
            onChange={handleChange}
          />
        </label>
        <div className="field-error">{errors.transmitterId}</div>
      </InputContainer>
      <InputContainer style={{ float: 'left' }}>
        <label>
          Participant Identifier:
          <Input
            type="text"
            name="externalParticipantId"
            maxLength={MAX_FIELD_LENGTH}
            value={dexcomParticipant.externalParticipantId}
            onChange={handleChange}
          />
        </label>
        <div className="field-error">{errors.externalParticipantId}</div>
      </InputContainer>
      <ErrorContainer>
        {errors.general}
      </ErrorContainer>
      <div style={{ clear: 'both' }} />
      {service.status === 'loading' && <div>Submitting...</div>}
      <div className="field-error">
        {errors.system}
      </div>
      <SaveButton disabled={!canSubmit()} type="submit" value="SAVE" />
      <CancelButton onClick={close} className="cancel-link-button-text">
        CANCEL
      </CancelButton>
    </Form>
  )
}

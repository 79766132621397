import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Content, Container, LinkButton } from '../common/simple-styled-components'
import { DexcomParticipant } from '../../types/dexcom-account'
import { getParticipantData } from '../../common/fetch-requests'
import { Pagination } from './pagination'
import useFilterList from '../../services/filter-list'
import { useExport, ExportState } from '../../services/export'
import { ParticipantList } from './participant-list'
import { download } from './csv-downloads'
import { PARTICIPANTS_PER_PAGE } from '../../config/constants'
import {ERROR_UNKNOWN_SYSTEM, MESSAGE_NO_PARTICIPANTS} from '../../config/strings'
import { ProgressModal, ErrorModal } from './export-modals'
import { EditModal } from './edit-modal'
import {
  ParticipantSearch,
  Search,
  SearchIcon,
  ExportDataButton,
  InfoText,
} from '../common/styled'

const Participants: React.FC = (): React.ReactElement => {
  const [selected, setSelected] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const {
    status,
    filteredData,
    filter,
    setFilter,
    toggleSort,
    sort,
  } = useFilterList([], 'lastName', true, getParticipantData)
  const { exportStatus, setToExport, reset } = useExport()
  const [editing, setEditing] = useState<DexcomParticipant | undefined>(
    undefined
  )

  useEffect(() => {
    if (exportStatus.state === ExportState.SUCCESS) {
      if (exportStatus.data) {
        download(exportStatus.data, exportStatus.fileName)
        reset()
      }
    }
  }, [exportStatus, reset])

  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value.toLowerCase())
  }

  const clickNext = () => {
    setSelected([])
    window.scrollTo(0, 0)
    setPage(page + 1)
  }

  const clickPrevious = () => {
    setSelected([])
    window.scrollTo(0, 0)
    setPage(page - 1)
  }

  const pageData = () =>
    filteredData.slice(
      page * PARTICIPANTS_PER_PAGE,
      page * PARTICIPANTS_PER_PAGE + PARTICIPANTS_PER_PAGE
    )

  const selectPage = (selecting: boolean) => {
    setSelected(
      selecting
        ? pageData()
            .filter((d) => d.uploadDate !== undefined)
            .map((d) => d.id)
        : []
    )
  }

  const selectRow = (id: string) => {
    if (selected.includes(id)) {
      selected.splice(selected.indexOf(id), 1)
      setSelected([...selected])
    } else {
      setSelected((selected) => [...selected, id])
    }
  }

  const handleColumnSort = (column: keyof DexcomParticipant) => {
    setSelected([])
    if (column === 'uploadDate') {
      toggleSort('uploadDate', 'Date')
    } else {
      toggleSort(column)
    }
  }

  const handleSingleExport = (id: string) => {
    const participant = pageData().find((p) => p.id === id)
    if (participant) {
      setToExport([participant])
    }
  }

  const handleBulkExport = () => {
    const participants: DexcomParticipant[] = []
    selected.forEach((s) => {
      const participant = pageData().find((p) => p.id === s)
      if (participant) participants.push(participant)
    })
    setToExport(participants)
  }

  const handleEdit = (id: string) => {
    const participant = pageData().find((p) => p.id === id)
    if (participant) {
      setEditing(participant)
    }
  }

  return (
    <Content>
      <h1>Participants</h1>
      <ParticipantSearch>
        <Search
          type="text"
          name="search"
          placeholder="Search"
          value={filter}
          onChange={search}
        />
        <SearchIcon />
      </ParticipantSearch>
      <Link className="link-button-text inline-link-button" to="/provision">
        <LinkButton>
          add participants
        </LinkButton>
      </Link>
      <ExportDataButton
        data-testid="export-button"
        disabled={selected.length < 2}
        onClick={handleBulkExport}
      >
        download data
      </ExportDataButton>
      <Container>
        <ParticipantList
          data={pageData()}
          selected={selected}
          sortedColumn={sort.key}
          export={handleSingleExport}
          changeSort={handleColumnSort}
          toggleSelectAll={selectPage}
          toggleSelectRow={selectRow}
          edit={handleEdit}
        />
        {status === 'loading' && <InfoText>Loading Participants...</InfoText>}
        {status === 'loaded' && filteredData.length === 0 && (
          <InfoText>{MESSAGE_NO_PARTICIPANTS}</InfoText>
        )}
        {status === 'error' && filteredData.length === 0 && (
          <InfoText>{ERROR_UNKNOWN_SYSTEM}</InfoText>
        )}
        <Pagination
          previous={clickPrevious}
          next={clickNext}
          page={page}
          total={filteredData.length}
          perPage={PARTICIPANTS_PER_PAGE}
        />
      </Container>
      <ProgressModal
        show={exportStatus.state === ExportState.IN_PROGRESS}
        close={reset}
      />
      <ErrorModal
        show={exportStatus.state === ExportState.ERROR}
        close={reset}
      />
      <EditModal
        show={editing !== undefined}
        participant={editing!}
        close={() => setEditing(undefined)}
      />
    </Content>
  )
}

export default Participants

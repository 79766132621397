import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin: 15px 10px 0 10px;
  text-align: right;
`
const Button = styled.button`
  background-color: white;
  color: var(--text-color);
  font-weight: normal;
  font-size: 14px;
  outline: none;
  border: none;

  &:hover {
    font-weight: bold;
  }
`
const Number = styled.a`
  user-select: none;
  color: var(--text-color);
  font-weight: normal;
  font-size: 14px;
  padding: 5px;
  curosr: default;
`
const SelectedNumber = styled(Number as any)`
  color: var(--white-color);
  background: var(--main-color);
`

const SelectedOrNot = (props: { selected: number; value: number }) => {
  return props.selected === props.value ? (
    <SelectedNumber>{props.value + 1}</SelectedNumber>
  ) : (
    <Number>{props.value + 1}</Number>
  )
}

interface Props {
  previous: () => void
  next: () => void
  page: number
  total: number
  perPage: number
}

export const Pagination = (props: Props) => {
  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const name = ev.currentTarget.name
    name === 'next' ? props.next() : props.previous()
  }

  // 1 2 3
  // 1 2 3 4
  // 1 2 3 4 5
  // 1 2 3 4 ... 17
  // 1 ... 13 14 15 ... 17
  // 1 ... 14 15 16 17
  const renderPageList = () => {
    let pages = Math.floor(props.total / props.perPage)
    if (props.total === 0) {
      return []
    }

    if (props.total % props.perPage !== 0) {
      pages += 1
    }

    if (pages < 6) {
      let numbers = new Array<JSX.Element>(pages)
        .fill(<></>)
        .map((v, i) => (
          <SelectedOrNot key={i} selected={props.page} value={i} />
        ))
      return numbers
    } else {
      if (props.page < 3) {
        // 6 placeholders
        let numbers = new Array<JSX.Element>(6).fill(<></>)
        numbers[0] = <SelectedOrNot key={0} selected={props.page} value={0} />
        numbers[1] = <SelectedOrNot key={1} selected={props.page} value={1} />
        numbers[2] = <SelectedOrNot key={2} selected={props.page} value={2} />
        numbers[3] = <Number key={3}>4</Number>
        numbers[4] = <Number key={4}>...</Number>
        numbers[5] = <Number key={5}>{pages}</Number>
        return numbers
      } else if (props.page < pages - 3) {
        // 7 placeholders
        let numbers = new Array<JSX.Element>(7).fill(<></>)
        numbers[0] = <Number key={0}>1</Number>
        numbers[1] = <Number key={1}>...</Number>
        numbers[2] = <Number key={2}>{props.page}</Number>
        numbers[3] = <SelectedNumber key={3}>{props.page + 1}</SelectedNumber>
        numbers[4] = <Number key={4}>{props.page + 2}</Number>
        numbers[5] = <Number key={5}>...</Number>
        numbers[6] = <Number key={6}>{pages}</Number>
        return numbers
      } else {
        // 6 placeholders
        let numbers = new Array<JSX.Element>(6).fill(<></>)
        numbers[0] = <Number key={0}>1</Number>
        numbers[1] = <Number key={1}>...</Number>
        numbers[2] = <Number key={2}>{pages - 3}</Number>
        numbers[3] = (
          <SelectedOrNot key={3} selected={props.page} value={pages - 3} />
        )
        numbers[4] = (
          <SelectedOrNot key={4} selected={props.page} value={pages - 2} />
        )
        numbers[5] = (
          <SelectedOrNot key={5} selected={props.page} value={pages - 1} />
        )
        return numbers
      }
    }
  }

  return (
    <Container>
      {props.page > 0 && (
        <Button name="previous" onClick={handleClick}>
          {'< BACK'}
        </Button>
      )}
      {renderPageList()}
      {(props.page + 1) * props.perPage < props.total && (
        <Button name="next" onClick={handleClick}>
          {'NEXT >'}
        </Button>
      )}
    </Container>
  )
}

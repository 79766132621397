import React, { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as ArrowDown } from '../../assets/ArrowDown.svg'
import UserService from '../../services/user'

const DownArrow = styled(ArrowDown)`
  color: var(--main-color);
  position: absolute;
  top: 25px;
  right: 15px;
  height: 15px;
  width: 15px;
`
const Dropdown = styled.div`
  margin-right: 10px;
  float: right;
  position: relative;
  display: inline-block;
`
const DropdownButton = styled.button`
  outline: none;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  background-color: white;
  margin-top: 5px;
  height: 50px;
  width: 253px;
  text-align: left;
  font-size: 14px;
`
const Username = styled.div`
  margin-top: 3px;
  font-weight: bold;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const DropdownContent = styled.div`
  border-radius: 3px;
  background-color: white;
  width: 253px;
  position: absolute;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  &:hidden {
    display: none;
  }
`
const LogoutButton = styled.button`
  color: rgb(36, 113, 201);
  font-size: 14px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  height: 36px;
  z-index: 2;

  &:hover {
    font-weight: bold;
  }
`

export const UserMenu = () => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleLogout = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    ev.preventDefault()
    UserService.doLogout()
  }

  window.onclick = (evt: any) => {
    let dropdown = document.getElementById('dropdown-container')

    if (evt.target !== dropdown && !dropdown!.contains(evt.target)) {
      setOpen(false)
    }
  }

  return (
    <Dropdown id="dropdown-container">
      <DropdownButton onClick={toggleOpen}>
        <div>Welcome</div>
        <Username>{UserService.getUsername()}</Username>
        <DownArrow />
      </DropdownButton>
      <DropdownContent hidden={!open} className="dropCntnt">
        <LogoutButton onClick={handleLogout}>Log Out</LogoutButton>
      </DropdownContent>
    </Dropdown>
  )
}

import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ListSortIcon } from '../../assets/Sort.svg'

const ListSort  = styled(ListSortIcon)`
  color: var(--main-color);
`

export const ScrollContainer = styled.div`
  padding: 0;
  margin: 0;
  overflow-y: auto;
`
export const Table = styled.table`
  user-select: none;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  margin-top: 5px;
`
export const TableHeader = styled.thead`
  width: 100%
  border-bottom: 2px solid black;
`
export const TableHeaderRow = styled.tr`
  text-align: left;
`
export const Header = styled.th`
  padding: 5px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
`
export const TableBody = styled.tbody`
  width: 100%;
`
export const TableFooter = styled.tfoot`
  width: 100%;
  border-bottom: 10px solid grey;
`
export const TableRow = styled.tr`
  text-align: left;
  border-bottom: 0.5px solid lightgrey;
`
export const TableData = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 5px 10px 5px;
`

const ActiveHeader = styled(Header as any)`
  font-weight: bold;
`
interface Props {
  fieldName: string
  sortedField: string
  title: string
  click: (fieldName: any) => void
}
export const ColumnHeader = (props: Props) =>
  props.fieldName === props.sortedField ? (
    <ActiveHeader onClick={() => props.click(props.fieldName)}>
      {props.title} <ListSort />
    </ActiveHeader>
  ) : (
    <Header onClick={() => props.click(props.fieldName)}>
      {props.title} <ListSort />
    </Header>
  )

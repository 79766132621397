import UserService from '../services/user'
import * as ENV from '../config/env'

export interface HttpResponse<T> extends Response {
  parsedBody?: T
}

export async function http<T>(
  request: RequestInfo,
  signal?: AbortSignal
): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request, { signal })
  try {
    // may error if there is no body
    response.parsedBody = await response.json()
  } catch (ex) {}

  return response
}

// http GET
export async function get<T>(path: string): Promise<HttpResponse<T>> {
  const token = await UserService.getToken()
  const request = new Request(
    `${ENV.REST_SERVICE_URL}/api/${path}`,
    {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
      method: 'GET',
    }
  )
  return await http<T>(request)
}

// http POST
export async function post<T>(
  path: string,
  body: any,
  abortSignal?: AbortSignal
): Promise<HttpResponse<T>> {
  const token = await UserService.getToken()
  const request = new Request(
    `${ENV.REST_SERVICE_URL}/api/${path}`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
        'x-requested-with': 'XmlHttpRequest',
      }),
    }
  )

  return http<T>(request, abortSignal)
}

export async function put<T>(
  path: string,
  body: any
): Promise<HttpResponse<T>> {
  const token = await UserService.getToken()
  const request = new Request(
    `${ENV.REST_SERVICE_URL}/api/${path}`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      }),
    }
  )

  return http<T>(request)
}

// http DELETE
export async function del(
  path: string,
  id: string
): Promise<HttpResponse<undefined>> {
  const token = await UserService.getToken()
  const request = new Request(
    `${ENV.REST_SERVICE_URL}/api/${path}/${id}`,
    {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }
  )

  return http<undefined>(request)
}

// string.ts - A place for defining string constants such as error messages and other messaging

export const ERROR_PARTICIPANT_EXPORT = "Unable to download data. Please contact your Dexcom Account Manager."
export const ERROR_UNKNOWN_SYSTEM = "System error. Please contact your Dexcom Account Manager."
export const ERROR_INVALID_DATA_FIELD = "Invalid entry."
export const ERROR_DATA_RULES = "Do not include spaces, single or special characters. Hyphens OK in name fields only."
export const ERROR_INVALID_DATA_CHAR_LENGTH = "Invalid entry. Entry must not exceed 64 characters."
export const ERROR_INVALID_DATA_TXID = "Invalid entry. Transmitter ID must start with 3 or 9."
export const ERROR_INVALID_DATA_TXID_LENGTH = "Invalid entry. Transmitter ID must not exceed 20 characters."
export const ERROR_DUPLICATE_TXID = "Transmitter ID {ID} already exists. Enter a new transmitter ID for each participant record."
export const MESSAGE_DUPLICATE_TXID = "A participant may be associated with more than one transmitter ID. A participant with more than one transmitter ID will have a separate participant record for each transmitter ID."
export const ERROR_PROVISION_PARTICIPANT = "Invalid entry. Please contact your Dexcom Account Manager."
export const ERROR_PROVISION_BULK = "One or more invalid entries found. No participant records were created. Please review all fields and re-upload all participant records within the CSV file."
export const ERROR_NOT_CSV = "Invalid file type. Please use provided CSV file template to upload multiple participant records. File size not to exceed 1 MB."
export const ERROR_LARGE_FILE = "File size exceeds 1 MB limit."
export const ERROR_UPLOADER_UNAVAILABLE = "The page you were looking for is currently unavailable. Please contact your Dexcom Account Manager."

export const MESSAGE_NO_PARTICIPANTS = "No data found."


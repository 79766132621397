import React, { useEffect, useState } from 'react'
import { FieldError } from '../../types/dexcom-account'
import {
  Table,
  TableHeaderRow,
  TableHeader,
  Header,
  ScrollContainer,
  TableBody,
  TableRow,
  TableData,
} from '../common/table-components'

type InvalidOrNot = 'INVALID' | 'DUPLICATE' | ''

interface ErrorRow {
  [index: string]: InvalidOrNot
  firstName: InvalidOrNot
  lastName: InvalidOrNot
  externalParticipantId: InvalidOrNot
  transmitterId: InvalidOrNot
}

interface ErrorRows {
  [index: string]: ErrorRow
}

interface Props {
  errors: FieldError[]
}

const ProvisionErrors = ({ errors }: Props) => {
  const [height, setHeight] = useState(window.innerHeight)
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (errors.length === 0) {
    return <div></div>
  }

  let rows: ErrorRows = {}
  errors.forEach((e) => {
    if (e.message !== 'INVALID' && e.message !== 'DUPLICATE') {
      return
    }

    if (!rows[e.row]) {
      rows[e.row] = {
        firstName: '',
        lastName: '',
        externalParticipantId: '',
        transmitterId: '',
      }
    }
    rows[e.row][e.field] = e.message
  })

  const rowEls = Object.keys(rows)
    .sort((a, b) => parseInt(a) - parseInt(b))
    .map((r) => (
      <TableRow key={r}>
        <TableData>{parseInt(r) + 1}</TableData>
        <TableData>{rows[r].firstName}</TableData>
        <TableData>{rows[r].lastName}</TableData>
        <TableData>{rows[r].externalParticipantId}</TableData>
        <TableData>{rows[r].transmitterId}</TableData>
      </TableRow>
    ))

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <Header>Row </Header>
            <Header>First Name </Header>
            <Header>Last Name </Header>
            <Header>Participant ID </Header>
            <Header>Transmitter ID </Header>
          </TableHeaderRow>
        </TableHeader>
      </Table>
      <ScrollContainer style={{ maxHeight: `${height - 600}px` }}>
        <Table>
          <TableBody>{rowEls}</TableBody>
        </Table>
      </ScrollContainer>
    </>
  )
}

export default ProvisionErrors

import React, { useState, useEffect } from 'react'
import { parse, ParseResult } from 'papaparse'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

import ProvisionErrors from './provision-errors'
import { ProvisionUpload } from './provision-upload'
import { DexcomParticipant } from '../../types/dexcom-account'
import { validateExternalData } from '../../common/validate'
import { Container } from '../common/simple-styled-components'
import { ERROR_LARGE_FILE, ERROR_NOT_CSV, ERROR_PROVISION_BULK, ERROR_UNKNOWN_SYSTEM } from '../../config/strings'

const FileContainer = styled.label`
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
  color: var(--white-color);
  background: var(--main-color);
  border-radius: 2px;
  overflow: hidden;
  position: relative;
`
const FileInput = styled.input`
  display: block;
  height: 0;
  width: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
`
const InfoStatus = styled.div`
  color: var(--error-color);
  padding-left: 10px;
  padding-bottom: 10px;
`

enum Step {
  ChooseFile,
  Upload,
  Done,
}

// interface Status {
//   main: string
//   subtext: string
// }

interface State {
  step: Step
  status: string
  data: DexcomParticipant[]
}

// Clears the file from the file input element
const clearFile = () => {
  const form = document.getElementById('data') as HTMLInputElement
  form.value = ''
}

// Simple component for rendering a status message. Used for displaying errors
// to the user.
const RenderStatus = (status: string) => (
  <InfoStatus>
    <div>{status}</div>
  </InfoStatus>
)

// ProvisionFormBulk Component
// Provides a mechanism for uploading csv files containing external participant
// data.
const ProvisionFormBulk = () => {
  const [state, setState] = useState<State>({
    step: Step.ChooseFile,
    data: [],
    status: '',
  })
  const [result, setResult] = useState<any>(undefined)

  useEffect(() => {
    if (result) {
      // handle the response
      // success: set step (done)
      if (!result.fieldErrors && !result.error) {
        setState((state) => ({
          ...state,
          step: Step.Done,
        }))
      }
      // error: set step(back)
      else if (result.fieldErrors) {
        setState((state) => ({
          ...state,
          step: Step.ChooseFile,
          status: ERROR_PROVISION_BULK,
        }))
      } else if (result.error === 'CANCELLED') {
        setState((state) => ({
          ...state,
          step: Step.ChooseFile,
          status: 'Upload was cancelled.',
        }))
      } else {
        setState((state) => ({
          ...state,
          step: Step.ChooseFile,
          status: ERROR_UNKNOWN_SYSTEM,
        }))
      }
    }
  }, [result])

  // Handle parsing the selected file and validating expected properties before
  // submitting a provision request.
  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResult(undefined)
    e.preventDefault()

    if (e.target.files) {
      const file = e.target.files[0]

      // check file size
      if (file.size / 1024 / 1024 > 1) {
        setState({
          ...state,
          status: ERROR_LARGE_FILE,
        })
        clearFile()
        return
      }

      // check file type
      if (file.type !== 'text/csv') {
        setState({
          ...state,
          status: ERROR_NOT_CSV,
        })
        clearFile()
        return
      }

      parse(file, {
        header: true,
        complete: (parseResult: ParseResult) => {
          if (parseResult.errors.length > 0) {
            setState({
              ...state,
              status: ERROR_NOT_CSV,
            })
            clearFile()
            return
          }

          if (parseResult.data.length > 0) {
            const errors = validateExternalData(parseResult.data)
            if (errors.length > 0) {
              setResult({
                ...result,
                fieldErrors: errors,
              })
              clearFile()
            } else {
              setState({
                ...state,
                step: Step.Upload,
                data: parseResult.data,
              })
            }
          }
        },
      })
    }
  }

  switch (state.step) {
    case Step.Done:
      return <Redirect to="/provision-success" />
    case Step.Upload:
      return (
        <Container>
          <h3>Creating Accounts</h3>
          <ProvisionUpload data={state.data} done={setResult} />
        </Container>
      )
    case Step.ChooseFile:
      return (
        <Container>
          <FileContainer>
            UPLOAD CSV FILE
            <FileInput
              data-testid="file-upload"
              type="file"
              id="data"
              name="file"
              accept="text/csv"
              onChange={uploadFile}
            />
          </FileContainer>
          <div style={{ padding: '10px' }}></div>
          {RenderStatus(state.status)}
          {result && result.fieldErrors && (
            <ProvisionErrors errors={result.fieldErrors} />
          )}
        </Container>
      )
    default:
      return <div>Unknown</div>
  }
}

export { ProvisionFormBulk }

import React, { useEffect, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { Redirect } from 'react-router-dom'
import { SpinnerModal } from './modals'
import UserService from '../../services/user'
import * as ENV from '../../config/env'
import './transmitter-upload.css'

interface LoadingState {
  loading: boolean
  unavailable: boolean
}

// Reset the uploader session. Required to prevent dangling sessions.
// Should run when user navigates to a different component and when the user
// navigates away from the application.
const resetUploader = () => {
  let reset_url = `${ENV.DISCOVERY_UPLOADER_URL}/auth/reset`
  let request = new XMLHttpRequest()
  request.open('GET', reset_url, true)
  request.withCredentials = true
  request.send()
}

export const TransmitterUpload = () => <IFrameUploader resetUploader={resetUploader} />

interface IIFrameUploaderProps {
  resetUploader: () => void
}
export const IFrameUploader = ({ resetUploader }: IIFrameUploaderProps) => {
  const [frame, setFrame] = useState(document.createElement('div'))
  const [state, setState] = useState<LoadingState>({
    loading: true,
    unavailable: false,
  })

  // Helps run code before the app is unloaded. If we have loaded the uploader frame,
  // request to have the session reset to prevent dangling sessions
  useBeforeunload(() => {
    if (frame.id === 'uploaderFrame') {
      resetUploader()
    }
  })

  useEffect(() => {
    let subscribed = true

    const createFrame = (f: HTMLIFrameElement) => {
      if (subscribed) setFrame(f)
    }

    console.log('loading uploader')
    let url = `${ENV.DISCOVERY_UPLOADER_URL}/auth/token?customer=${UserService.getRbe() || ENV.DEFAULT_RBE}&check_tokens=true`

    UserService.getTokens().then((ts) => {
      let httpRequest = new XMLHttpRequest()
      httpRequest.onreadystatechange = function () {
        if (this.readyState === 4) {
          switch (this.status) {
            case 200:
              let f = document.createElement('iframe')
              f.setAttribute('id', 'uploaderFrame')
              f.setAttribute('title', 'uploader')
              f.setAttribute('data-testid', 'uploader-frame')
              f.setAttribute('src', this.responseXML?.URL || '')
              createFrame(f)
              setFrame(f)
              setState({
                loading: false,
                unavailable: false,
              })
              break
            default:
              setState({
                loading: false,
                unavailable: true,
              })
              break
          }
        }
      }
      httpRequest.open('POST', url, true)
      httpRequest.setRequestHeader(
        'Content-Type',
        'application/json;charset=UTF-8'
      )
      httpRequest.responseType = 'document'
      httpRequest.withCredentials = true
      httpRequest.send(
        JSON.stringify({
          id_token: ts.idToken,
          access_token: ts.accessToken,
        })
      )
    })

    // Call the reset endpoint to reset the session associated with this resource.
    // Called when this component is unmounted from the virtual DOM
    return () => {
      subscribed = false
      resetUploader()
    }
  }, [resetUploader])

  useEffect(() => {
    const uploader = document.getElementById('uploader')
    if (uploader) {
      uploader.innerHTML = ''
      uploader.appendChild(frame)
    }
  }, [frame])

  if (state.unavailable) return <Redirect to="/unavailable" />
  return (
    <div id="uploader">
      <SpinnerModal show={state.loading} />
    </div>
  )
}

const comparators = new Map<string, any>([
  [
    'string',
    (a: string, b: string) => {
      const aString = a ? a : ''
      const bString = b ? b : ''
      return aString.localeCompare(bString)
    },
  ],
  [
    'number',
    (a: number, b: number) => {
      const aNum = a ? a : 0
      const bNum = b ? b : 0
      return aNum - bNum
    },
  ],
  [
    'Date',
    (a: string, b: string) => {
      const aDate = a ? Date.parse(a) : new Date(0).getTime()
      const bDate = b ? Date.parse(b) : new Date(0).getTime()
      return aDate - bDate
    },
  ],
])

export function sort<T>(
  array: Array<T>,
  key: keyof T,
  comparatorType: 'string' | 'number' | 'Date',
  desc: boolean = false
): Array<T> {
  return s(array, key, comparators.get(comparatorType), desc)
}

function s<T>(
  array: Array<T>,
  key: keyof T,
  compareFn: (a: any, b: any) => number,
  desc = false
): Array<T> {
  return array.sort((a, b) => compareFn(a[key], b[key]) * (desc ? -1 : 1))
}

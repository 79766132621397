import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import { ReactComponent as DangerIcon } from '../../assets/danger.svg'
import { ReactComponent as CloseIcon } from '../../assets/close-24px.svg'
import { Spinner } from '../common/styled'
import { ERROR_PARTICIPANT_EXPORT } from '../../config/strings'

const Close = styled.button`
  background-color: white;
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  outline: none;
  color: grey;

  &:hover {
    color: black;
  }
`
const SpinnerContainer = styled.div`
  margin-left: 230px;
  margin-top: 60px;
`
const Label = styled.p`
  text-align: center;
  margin: -10px 0 0 0;
  font-size: 20px;
  font-weight: normal;
`
const Danger = styled(DangerIcon)`
  margin-top: 20%;
  margin-left: 50%;
  height: 80px;
  width: 80px;
  transform: translate(-50%, -50%);
`
const customStyles = {
  content: {
    padding: '0',
    width: '550px',
    height: '250px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

interface Props {
  show: boolean
  close: () => void
}
export const ProgressModal = (props: Props) => (
  <Modal
    appElement={document.getElementById('root')!}
    isOpen={props.show}
    style={customStyles}
  >
    <Close data-testid="cancel" onClick={props.close}>
      Cancel
    </Close>
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
    <Label>Exporting...</Label>
  </Modal>
)
export const ErrorModal = (props: Props) => (
  <Modal
    appElement={document.getElementById('root')!}
    isOpen={props.show}
    style={customStyles}
  >
    <Close onClick={props.close}>
      <CloseIcon />
    </Close>
    <Danger />
    <Label data-testid="export-error">{ERROR_PARTICIPANT_EXPORT}</Label>
  </Modal>
)

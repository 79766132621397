import { useState, useEffect } from 'react'
import { Service } from '../../types/service'
import {
  ExternalParticipant,
  DexcomParticipant,
} from '../../types/dexcom-account'
import { post, HttpResponse, put } from '../../common/http'

const useProvisionParticipantService = (update: boolean) => {
  const [service, setService] = useState<Service>({
    status: 'init',
  })
  const [data, setData] = useState<ExternalParticipant | undefined>()

  useEffect(() => {
    let subscribed = true

    const reportRes = (service: Service) => {
      if (subscribed) {
        setService(service)
      }
    }

    // Data has been set, we should send it...
    if (data) {
      setService({ status: 'loading' })

      let action: Promise<HttpResponse<DexcomParticipant>>
      if (update) {
        action = put('participants', data)
      } else {
        action = post('participants', data)
      }

      action.then((res) => {
        if (res.ok) {
          reportRes({ status: 'loaded' })
        } else if (res.status === 409) {
          reportRes({ status: 'error', error: new Error('DUPLICATE') })
        } else {
          console.log('errir')
          reportRes({ status: 'error', error: new Error(res.statusText) })
        }
      })
      .catch(_e => {
        reportRes({ status: 'error', error: new Error('System Error')})
      })
    }
    return () => {
      subscribed = false
    }
  }, [data, update])

  const provisionParticipant = (data: ExternalParticipant) => {
    setData(data)
  }

  return {
    service,
    provisionParticipant,
  }
}

export { useProvisionParticipantService }

import { FieldError } from '../types/dexcom-account'

// USE THESE TO CREATE new RegExp()
export const NAME_REGEX = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)?$/g
export const PID_REGEX = /^[a-zA-Z0-9]+$/g
export const TXID_REGEX = /^(3|9)[a-zA-Z0-9]+$/g

// Returns false when value is...
//    not alphanumeric
//    not between 2 and 64 characters inclusive
//    contain special characters other than a single hyphen
const isValidName = (value: string): boolean => {
  if (value.length < 2 || value.length > 64) return false
  return new RegExp(NAME_REGEX).test(value)
}

// Returns false when value is ...
//  not alphanumeric
//  not between 2 and 64 characters
const isValidPID = (value: string): boolean => {
  if (value.length < 2 || value.length > 64) return false
  return new RegExp(PID_REGEX).test(value)
}

// Returns false when value...
//    does not start with 3 or 9
//    is not alphanumeric
//    is not between 2 and 20 characters inclusive
const isValidTXID = (value: string): boolean => {
  if (value.length < 2 || value.length > 20) return false
  return new RegExp(TXID_REGEX).test(value)
}

const validateRow = (row: any, index: number): FieldError[] => {
  let errors: FieldError[] = []

  // Check each field
  if (!row.firstName || !isValidName(row.firstName)) {
    errors.push({
      row: index,
      status_code: 0,
      field: 'firstName',
      message: 'INVALID',
    })
  }

  if (!row.lastName || !isValidName(row.lastName)) {
    errors.push({
      row: index,
      status_code: 0,
      field: 'lastName',
      message: 'INVALID',
    })
  }

  if (!row.externalParticipantId || !isValidPID(row.externalParticipantId)) {
    errors.push({
      row: index,
      status_code: 0,
      field: 'externalParticipantId',
      message: 'INVALID',
    })
  }

  if (!row.transmitterId || !isValidTXID(row.transmitterId)) {
    errors.push({
      row: index,
      status_code: 0,
      field: 'transmitterId',
      message: 'INVALID',
    })
  }

  return errors
}

// Validate external data against required fields and field requirements
const validateExternalData = (data: any[]): FieldError[] => {
  let errors: FieldError[] = []

  // hold the txids
  let txids: { [txid: string]: number } = {}

  data.forEach((r, i) => {
    let rowErrors = validateRow(r, i)
    let txidError = rowErrors.find((e) => e.field === 'transmitterId')
    if (!txidError) {
      // The txid field is valid
      // But is it a duplicate
      let txid: string = r.transmitterId
      if (txids[txid] === undefined) {
        txids[txid] = i
      } else {
        rowErrors.push({
          row: i,
          status_code: 0,
          field: 'transmitterId',
          message: 'DUPLICATE',
        })

        let duplicate = errors.find(
          (e) => e.row === txids[txid] && e.message === 'DUPLICATE'
        )
        if (!duplicate) {
          errors.push({
            row: txids[txid],
            status_code: 0,
            field: 'transmitterId',
            message: 'DUPLICATE',
          })
        }
      }
    }
    errors.push(...rowErrors)
  })

  return errors
}

export { validateExternalData, validateRow, isValidName, isValidTXID, isValidPID }

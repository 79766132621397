import React, { useState } from 'react'

import { ExternalParticipant } from '../../types/dexcom-account'
import { Container } from '../common/simple-styled-components'
import { ParticipantEdit } from '../participant-edit'
import './provision-single-form.css'
import { Redirect } from 'react-router-dom'

const emptyParticipant: ExternalParticipant = {
  externalParticipantId: '',
  transmitterId: '',
  firstName: '',
  lastName: '',
}

export const ProvisionSingleForm = () => {
  const [cancel, setCancel] = useState(false)

  if (cancel) {
    return <Redirect to="/" />
  }

  return (
    <Container>
      <ParticipantEdit
        participant={emptyParticipant}
        edit={false}
        close={() => setCancel(true)}
      />
    </Container>
  )
}

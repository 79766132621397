import { useState, useEffect, useRef } from 'react'
import { post, HttpResponse, del } from '../../common/http'
import {
  FieldError,
  ExternalParticipant,
  DexcomParticipant,
} from '../../types/dexcom-account'

interface DataError {
  errors: [
    {
      field: string
      message: string
    }
  ]
}

interface Result {
  fieldErrors?: FieldError[]
  error?: string
}

interface Response<T> {
  row: number
  res?: HttpResponse<T>
  err?: Error
}

interface RowError {
  fieldErrors: FieldError[]
  error?: string
}

interface IProgress {
  successful: DexcomParticipant[]
  errors: RowError[]
}

export const useDataProgress = (participantData: ExternalParticipant[]) => {
  const [result, setResult] = useState<Result | undefined>(undefined)
  const [processed, setProcessed] = useState<IProgress>({
    successful: [],
    errors: [],
  })
  const [progress, setProgress] = useState(0)
  const cancelled = useRef(false)
  const done = useRef(false)

  // Effect for watching the progress of the upload and handling the responses
  // as well as managing the state of a user cancel action.
  useEffect(() => {
    let subscribed = true

    const updateResult = (r: Result) => {
      if (subscribed) {
        setResult(r)
      }
    }

    if (done.current) {
      // User cancelled or there are errors, so the successful requests must be rolled back
      if (
        cancelled.current ||
        processed.successful.length < participantData.length
      ) {
        const revertStack = processed.successful.map((p) =>
          del('participants', p.id)
        )

        // After the rollbacks have finished, process the reason
        Promise.all(revertStack).then((_r) => {
          if (cancelled.current) {
            updateResult({ error: 'CANCELLED' })
          } else if (processed.errors.length > 0) {
            let fieldErrors: FieldError[] = []

            // Loop through errors. Expecting Field errors, but if a plain error is found
            // set result and end.
            for (let i = 0; i < processed.errors.length; i++) {
              if (processed.errors[i].error) {
                updateResult({ error: processed.errors[i].error })
                return
              }

              fieldErrors.push(...processed.errors[i].fieldErrors)
            }

            updateResult({ fieldErrors: fieldErrors })
          }
        })
      } else {
        // All the requests were successful and the action was not canceled
        updateResult({})
      }
    }

    return () => {
      subscribed = false
    }
    // eslint-disable-next-line
  }, [processed, cancelled])

  // Run a side effect when participantData changes. Converts the data array to
  // a promise array around a fetch request. Keeps track of successful requests
  // and rolls them back by calling DELETE if all requests were not successful.
  useEffect(() => {
    let subscribed = true

    const updateRowSuccess = (participant: DexcomParticipant) => {
      if (subscribed) {
        setProcessed((processed) => ({
          ...processed,
          successful: [...processed.successful, participant],
        }))
      }
    }

    const updateRowError = (error: RowError) => {
      if (subscribed) {
        setProcessed((processed) => ({
          ...processed,
          errors: [...processed.errors, error],
        }))
      }
    }

    const incrementProgress = () => {
      if (subscribed) {
        setProgress((progress) => progress + 1)
      }
    }

    const upload = async () => {
      let error = false
      for (let i = 0; i < participantData.length; i++) {
        if (cancelled.current || error) {
          break // stop processing
        }

        let participantRow: ExternalParticipant = {
          ...participantData[i],
          transmitterId: participantData[i].transmitterId.toUpperCase()
        }
        const { row, res, err } = await post('participants', participantRow)
          .then((res) => {
            incrementProgress()
            return { row: i, res: res } as Response<DexcomParticipant>
          })
          .catch((e) => {
            incrementProgress()
            return { row: i, err: e } as Response<DexcomParticipant>
          })

        if (res) {
          if (res.ok) {
            updateRowSuccess(res.parsedBody!)
          } else if (res.status === 409) {
            const error = {
              row: row,
              status_code: res.status,
              message: 'DUPLICATE',
              field: 'transmitterId',
            }
            updateRowError({ fieldErrors: [error] })
          } else if (res.status === 400) {
            let fieldErrors: FieldError[] = []
            const { errors } = (res.parsedBody! as any) as DataError
            errors.forEach((e) => {
              fieldErrors.push({
                row: row,
                status_code: res.status,
                message: 'INVALID',
                field: e.field,
              })
            })
            updateRowError({ fieldErrors: fieldErrors })
          } else {
            updateRowError({ fieldErrors: [], error: res.statusText })
            error = true // cancel the rest of the processing
          }
        } else {
          updateRowError({ fieldErrors: [], error: err?.message || '' })
          error = true // cancel the rest of the processing
        }
      }

      done.current = true
    }

    if (participantData.length > 0) {
      upload()
    }

    return () => {
      subscribed = false
    }
    // eslint-disable-next-line
  }, [])

  const cancel = () => {
    cancelled.current = true
  }

  return {
    progress,
    result,
    cancel,
    cancelled: cancelled.current,
  }
}

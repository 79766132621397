import React, { useEffect } from 'react'
import { useDataProgress } from '../../services/data-progress'
import { DexcomParticipant } from '../../types/dexcom-account'
import { BlockButton } from '../common/simple-styled-components'
import './provision-upload.css'

interface Props {
  data: DexcomParticipant[]
  done: React.Dispatch<React.SetStateAction<any>>
}

export const ProvisionUpload = ({ data, done }: Props) => {
  const { progress, result, cancel, cancelled } = useDataProgress(data)

  /// Effect for watching the result of data progress
  useEffect(() => {
    if (result) {
      if (cancelled) {
        done({ error: 'CANCELLED' })
        return
      }
      setTimeout(() => {
        done(result)
      }, 1000)
    }
  }, [result, done, cancelled])

  const doCancel = (evt: React.PointerEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    cancel()
  }

  return (
    <div>
      {cancelled && <span>Cancelling...</span>}
      <div id="progress">
        <div id="progress-bar">
          <span
            style={{
              width: `${
                data.length === 0 ? 0 : (progress / data.length) * 100
              }%`,
            }}
          ></span>
        </div>
        <span>{`${
          data.length === 0 ? 0 : ((progress / data.length) * 100).toFixed(0)
        }%`}</span>
      </div>
      <BlockButton
        disabled={cancelled || progress === data.length}
        className="block-button"
        onClick={doCancel}
      >
        Cancel
      </BlockButton>
    </div>
  )
}

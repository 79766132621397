let window_any = window as any
export const DISCOVERY_UPLOADER_URL    = window_any.DISCOVERY_UPLOADER_URL
export const REST_SERVICE_URL          = window_any.REST_SERVICE_URL
export const DEXCOM_KEYCLOAK_URL       = window_any.DEXCOM_KEYCLOAK_URL
export const DEXCOM_KEYCLOAK_REALM     = window_any.DEXCOM_KEYCLOAK_REALM
export const DEXCOM_KEYCLOAK_CLIENT_ID = window_any.DEXCOM_KEYCLOAK_CLIENT_ID
export const PARTICIPANTS_PER_PAGE     = window_any.PARTICIPANTS_PER_PAGE
export const DEFAULT_RBE               = window_any.DEFAULT_RBE
export const LINK_DEXCOM_CONTACT       = window_any.LINK_DEXCOM_CONTACT
export const LINK_PRIVACY_POLICY       = window_any.LINK_PRIVACY_POLICY
export const LINK_TERMS_OF_USE         = window_any.LINK_TERMS_OF_USE
export const LINK_SOFTWARE_GUIDE       = window_any.LINK_SOFTWARE_GUIDE
export const LINK_G6_HANDOUT           = window_any.LINK_G6_HANDOUT

import React from 'react'
import { Link } from 'react-router-dom'
import { LinkButton, Content } from '../common/simple-styled-components'
import './error.css'

export default () => (
  <Content>
    <h1>404 Page Not Found</h1>
    <p className='error'>The page you were looking for does not exist.</p>
    <Link className="link-button-text" to="/">
      <LinkButton>
        back to participant list
      </LinkButton>
    </Link>
  </Content>
)
